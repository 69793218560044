import {isParentDom} from "@/components/ZCUtil";
import React, {useEffect, useRef, useState} from "react";
import {IFormItemProps} from "../FormItemEx/interface";
import HotOptions from "./hotoptions";
import './index.less';
import {Option} from "./options";

type borderedType = 'all' | 'bottom' | 'none'

const borderedMap = {
    'all': 'zc-area-input-wrap',
    'bottom': 'zc-area-input-bottom-wrap',
    'none': 'zc-input-none'
}
const activeMap = {
    'all': 'zc-area-input-wrap-active',
    'bottom': 'zc-area-input-bottom-wrap-active',
    'none': 'input-bottom-active'
}
// const errMap = {
//     'all': 'errorMsg',
//     'bottom': 'errorMsg-bottom',
//     'none': 'errorMsg-bottom'
// }


export interface ISelectArea {
    key: string,
    title: string,
    select: any,
    pid?: number,
}

interface Props extends IFormItemProps {
    onSelect?: any
    value?: any
    hotcity?: boolean
    labelInValue?: boolean
    style?: any
    hidelabel?: boolean
    bordered?: borderedType
}
const ZSelectArea = (props:Props)=> {
    const {extra = {}, bordered = 'all', size} = props


    const {getLabel} = extra

    const [visible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputIds, setInputIds] = useState('');
    const [activeIndex, setActvieIndex] = useState(6);
    const [provinceData, setPrivinceData] = useState([]);
    const baseData = [
        {key: 'province', title: '省份', pid: 0, select: {}},
        {key: 'city', title: '城市', select: {}},
        {key: 'area', title: '区/县', select: {}}
    ]
    const [options, setOptions] = useState(baseData)
    const [isArrowHover, setIsArrowHover] = useState(false);

    const containerRef = useRef<any>();
    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (!isParentDom(e.target, containerRef.current)) {
                setVisible(false)
            }
        })
    }, [])


    useEffect(() => {
        const initVal = props.value?.value || props.value

        if (initVal) {
            setInputIds(initVal);
            const pidList = [0].concat(initVal.split('/'));
            const newOptions = options.map((item: ISelectArea, index: number) => {
                return {...item, pid: pidList[index], select: {value: initVal.split('/')[index]}}
            })
            setOptions(newOptions);
        }
    },[props?.value])

    useEffect(() => {
        // 获取label 创建订单使用
        const names = options.reduce((result: any,i:any) => {
            result[i.key] = i.select.label
            return result
        },{})
        getLabel?.(names)
    },[options])

    const onOpenSelect = ()=>{
        if(!visible){
            setVisible(true);
            setActvieIndex(props.hotcity ? 6 : 0);
        }
    }
    const sizeClass = {
        wrap: `${borderedMap[bordered]} ${visible ? activeMap[bordered] : ''} zc-area-wrap-height-${size}`,
        optionWrap: `zc-area-option-wrap ${!visible ? 'zc-area-option-wrap-hide' : ''} zc-area-option-top-${size}`,
        inputWrap: `${!props.hidelabel ? "zc-area-input-pdl" : "zc-area-input"} zc-area-wrap-height-${size}`,
    }

    // 选中某一项
    const onOptionSelect = (index:number,params:any)=>{
        if(params){
            let optionsData = options;
            optionsData[index] = {...optionsData[index],select:params};
            const nextIndex = index+1;
            if(nextIndex <= options.length-1){
                optionsData = optionsData.map((item:any,i:number)=>{
                    const needClear = i>index;
                    return needClear?{...item,select:{},pid:undefined}:item
                })
                optionsData[nextIndex] = {...optionsData[nextIndex],pid:params.value};
                //选了 前面的项 需要清空后面的 pid 让组件
                setOptions(optionsData);
                setActvieIndex(nextIndex);
            } else {
                setVisible(false)
            }
            const names = optionsData.filter((item: any) => {
                return item.select?.label
            }).map((item: any) => {
                return item?.select?.label
            }).join('/');
            const ids = optionsData.filter((item: any) => {
                return item.select?.label
            }).map((item: any) => {
                return item?.select?.value
            }).join('/');

            if (index === 2) {
                if (props.onChange) {
                    setInputValue(names);
                    setInputIds(ids);
                    if (props.labelInValue) {
                        props.onChange({label: names, value: ids})
                    } else {
                        props.onChange(ids)
                    }
                }
            }
        }
    }

    const onHostCitySelect = (e:any)=> {
        const baseOptions = options;
        const privinceData: any = provinceData?.find((item: any) => {
            return item.value == e.pid
        });
        baseOptions[0] = {...baseOptions[0], select: {label: privinceData.label, value: e.pid}};
        baseOptions[1] = {...baseOptions[1], pid: e.pid, select: {label: e.label, value: e.value}};
        baseOptions[2] = {...baseOptions[1], pid: e.value};
        setOptions(baseOptions);
        setActvieIndex(2)
    }

    const onAreaDataLoad = (e:any)=>{
        if(e[0]){
            setPrivinceData(e[0])
        }
    }

    const onFillLabel = (index:number,data:any)=>{
        let optionsData = options;
        optionsData[index] = {...optionsData[index],select:data};
        setOptions(JSON.parse(JSON.stringify(optionsData)));
        const names = options.map((item:any)=>{ return item.select.label }).join('/');
        const ids = options.map((item:any)=>{ return item.select.value }).join('/')
        setInputIds(ids);
        setInputValue(names);

    }
    const toggle = () => {
        if (props?.disabled) {
            return
        }
        setInputValue('');
        setInputIds('');
        setOptions(baseData);
        props.onChange?.('')
    }
    return (
        <div className={`zc-area-container ${props.disabled && 'zc-area-container-disable'}`} ref={containerRef}
             style={{width: props.width, ...props.style}}>
            <div className={sizeClass.wrap}
                 onClick={() => {
                     if (!props.disabled) {
                         onOpenSelect()
                     }
                 }}
                 onMouseEnter={() => {
                     if (!props.disabled) {
                         setIsArrowHover(true)
                     }
                 }}
                 onMouseLeave={() => {
                     if (!props.disabled) {
                         setIsArrowHover(false)
                     }
                 }}>
                <input style={{display: 'none'}} value={inputIds || ''} readOnly name={props.fld} autoComplete="off"/>
                <div className={sizeClass.inputWrap}>
                    {inputValue || <span style={{color: '#999999'}}>{props.placeholder}</span>}
                </div>
                <div className="zc-area-right" onClick={toggle}>
                    {!(isArrowHover && inputValue) &&
                    <i className={visible ? "iconfont icon-jiantou-shang" : "iconfont icon-jiantou-xia"}/>}
                    {(isArrowHover && inputValue) && <i className="clear-icon iconfont icon-guanbi"/>}
                </div>
            </div>
            <div className={sizeClass.optionWrap}>
                <div className="zc-area-wrap">
                    <div className="zc-area-top-wrap">
                        {props.hotcity && <div onClick={() => setActvieIndex(6)}
                                               className={activeIndex == 6 ? "zc-area-top-active" : "zc-area-top"}>热门城市</div>}
                        {
                            baseData?.map((item: ISelectArea, index: number) => {
                                return (<div key={index} onClick={() => setActvieIndex(index)}
                                             className={index == activeIndex ? "zc-area-top-active" : "zc-area-top"}>{item.title}</div>)
                            })
                        }
                    </div>
                    {props.hotcity && <HotOptions active={activeIndex == 6} data={options.length > 2 && options[1]}
                                                  onChange={onHostCitySelect}/>}
                    {
                        options?.map((item: ISelectArea, index: number) =>
                            <Option onLoadData={onAreaDataLoad}
                                    onLabelChange={(data: any) => onFillLabel(index, data)}
                                    key={index}
                                    active={activeIndex == index}
                                    data={item}
                                    onChange={(params: any) => onOptionSelect(index, params)}/>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ZSelectArea;