import React,{useState,useEffect} from "react";

export interface Props{
  data: any,        // 源数据
  color?: string,
  keyword: any,  // 关键字
  flags?: boolean  // 区分大小写   true 区分  false- 不区分
}
const Html2Text = (props:Props) => {
  const {
    data,
    color = 'red',
    keyword,
    flags = false
  } = props
  const [item, setItem] = useState(data)
  const pattern = /<(\S*)[^>]*>[^<]*<\/(\1)>/gi;

  const c = /<[^>]+>/gi;
  const getArr = (data: string[], textLen: number, j: number) => {
    if (textLen > 1) {
      data[j + 1 - textLen] = ''
      getArr(data, textLen - 1, j)
    }
  }

  const getKey = (key: any) => {
    if (!key) {
      return ''
    }
    const isString: any = typeof key === "string"
    if (isString) {
      return key
    } else {
      return `(${key.join('|')})`
    }
  }

  const getResult = () => {
    const reg = new RegExp(getKey(keyword), !flags && 'i' || '');
    const contentArr = data?.match(reg || pattern) // 获取符合条件的字符
    if (contentArr) {
      const dataString = data.replace(c, '') // 去除标签后的字符串
      const text = contentArr[0].replace(c, '') // 字符串内的内容
      const str2arr = dataString.split('');
      const len = str2arr.length
      const textLen = text.length
      for (let i = 0; i < len; i++) {
        let s = ''
        for (let j = i; j < len; j++) {
          s += str2arr[j]
          if (s === text) {
            str2arr[j] = text
            getArr(str2arr,textLen, j)
            break
          }
        }
      }
      str2arr.map((item: any, index: number) => {
        if (item === text) {
          str2arr[index] = <em key={index} style={{color}}>{text}</em>
          return item
        }
      })
      return str2arr.map((i: any) => i)
    } else {
      return data
    }
  }

  useEffect(() => {
    setItem(getResult())
  }, [data, keyword])
  return item
}
export default Html2Text
