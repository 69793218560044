import React from "react"
import "./index.less"

interface IProps{
    text?:string,
    className?:string,
    style?:any
}


export const Empty = (props:IProps)=>{
    return (
        <div className='zc-table-empty color-999'>
            <img src="/images/nodata.jpg" alt=""/>
            <div> 暂无数据</div>
        </div>
    )
}