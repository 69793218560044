
/**
 *  判断当前节点是否为子节点
 * @param child  子节点
 * @param Parent  父节点
 * @returns 
 */
export const isParentDom = (child:any,Parent:any)=>{
        while (child != undefined && child != null && child.tagName.toUpperCase() != 'BODY'){
            if (child == Parent){
                return true;
            }
            child = child.parentNode;
        }
        return false;
}
