import { Api } from "@/api";
import React, { useEffect, useState } from "react";


interface IProps{
    onChange:(params?:any)=>void,
    active?:boolean ,
    data:any   
}


export const HotOptions = (props:IProps)=>{
       const { onChange,active,data } = props;
       const [dataSource,setDataSource] = useState<{label?:any,value?:string}[]>([]);
       useEffect(()=>{
                Api.Division.GetHotCitys().then((res:any)=>{
                    if(res.state){
                        const result = res.data.map((item:{code:string,name:string,parentCode:string})=>{ 
                            return  {label:item.name,value:item.code,pid:item.parentCode} 
                        })
                        setDataSource(result);
                    }
                }) 
       },[])
       return (
           <div className={active?"zc-area-list-wrap":"zc-area-list-wrap-hide"}>
               {dataSource.map((item,index)=>{
                   return (
                        <span key={index} className={item.value==data.select.value?'zc-area-item-text-active':'zc-area-item-text'}
                                    onClick={()=>{  
                                       onChange(item)
                                    }}>
                            {item.label}
                        </span>
                   )
               })}
           </div>
       )          
}

export default HotOptions;