import React, { useEffect, useState } from "react";
import { ISelectArea } from "..";
import { getDataByPid } from "../AreaStorge";


interface IProps{
    value?: any,
    onChange: (params?: any) => void,
    active?: boolean,
    data: ISelectArea,
    onLoadData: any,
    onLabelChange: any
}


export const Option = (props: IProps) => {

    const {onChange, active, data, onLoadData, onLabelChange} = props;
    const pid: any = data.pid;
    const [dataSource, setDataSource] = useState<{ label?: any, value?: string }[]>([]);

    useEffect(() => {
        if (pid || pid == '0') {
            getDataByPid(pid || "0").then((res: any) => {
                if (res) {
                    const result = res.map((item: { code: string, name: string }) => {
                        return {label: item.name, value: item.code}
                    })
                    setDataSource(result);
                    if (onLoadData) {
                        props.onLoadData({[pid]: result})
                    }


                }
            })
        } else {
            setDataSource([])
        }
    }, [pid])

    useEffect(() => {
        if (dataSource.length > 0 && data) {
            if (data?.select?.value && !data?.select?.label) {
                const select = dataSource.find((item: any) => {
                    return props.data.select.value == item.value
                })
                if (select) {
                    onLabelChange(select);
                }
            }
        }
    }, [dataSource, data])

    return (
        <div className={active ? "zc-area-list-wrap" : "zc-area-list-wrap-hide"}>
            {dataSource.map((item, index) => {
                return (
                    <span key={index}
                          className={item.value == data.select.value ? 'zc-area-item-text-active' : 'zc-area-item-text'}
                          onClick={() => {
                              onChange(item)
                          }}>
                            {item.label}
                        </span>
                )
            })}
        </div>
    )
}