import { Api } from "@/api"



export const getDataByPid = (pid: string) => {
    return new Promise((reslove, reject) => {
        let beforeData: any = localStorage.getItem('areadata');
        if (beforeData) {
            beforeData = JSON.parse(beforeData);
            // 60 天过期
            if ((new Date().getTime() - beforeData.time * 1) > 1000 * 60 * 60 * 24 * 60) {
                localStorage.removeItem('areadata');
            }
            if (beforeData?.data) {
                const areaMap = beforeData.data;
                const result = areaMap[pid];
                if (result) {
                    reslove(result)
                } else {
                    Api.Division.GetDivisions({ ParentCode: pid || "0" }).then((res: any) => {
                        beforeData.data[pid] = res.data;
                        reslove(res.data)
                        localStorage.setItem('areadata', JSON.stringify(beforeData))
                    })
                }
            } 
        }else {
            Api.Division.GetDivisions({ ParentCode: pid || "0" }).then((res: any) => {
                reslove(res.data)
                const storageData = { time: new Date().getTime(), data: { [pid]: res.data } }
                localStorage.setItem('areadata', JSON.stringify(storageData))
            })
        }
    })
}



