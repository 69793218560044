// 生成随机数
export const getRandomKey = ()=>{
    return  Date.now()+Math.ceil(Math.random()*10);
}

export const arr2tree = (source: any, filer?: { parentCode?: string, code?: any }) => {
    // return
    const {parentCode = 'parentId', code = 'id'} = filer || {}
    let jsonMap = new Map()
    // 匹配上下级 保存map {
    // 父级 :  [子级]
    // }
    source.forEach((item: any) => {
        if (jsonMap.has(item[parentCode])) {
            jsonMap.get(item[parentCode]).push(item)
        } else {
            jsonMap.set(item[parentCode], [item])
        }
    })
    // 上下级 挂载
    const newArr = source.reduce((result: any, item: any) => {
        result.push(
            {
                ...item,
                productDtos: jsonMap.get(item[code]) || []
            }
        )
        return result
    }, [])
    // 筛选 第一级
    return newArr.filter((i: any) => !i.hasOwnProperty(parentCode))
}
export const isEmpty = (val: any) => {
    // null or undefined
    if (val == null) return true;

    if (typeof val === 'boolean') return false;

    if (typeof val === 'number') {
        if (val == 0) {
            return false
        } else {
            return !val;
        }
    }else{
        return val
    }
}


export const object2Query = (params:{})=>{
    return Object.keys(params).reduce((t,k,i)=>{
        return `${t}${i==0?'':'&'}${k}=${params[k]}`
    },'?')
}
