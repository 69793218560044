import {Api} from "@/api";
import {ISelect} from "@/components/Select/helper";
import {isParentDom} from "@/components/ZCUtil";
import React, {useEffect, useRef, useState} from "react";
import {Empty} from "../Empty";
import {IFomItemSize} from "../FormItemEx/interface";
import Html2Text from "../Html2text";
import './index.less';
import {IDestination, IFbaWareResp} from "./interface";

type borderedType = 'all' | 'bottom' | 'none'

const borderedMap = {
    'all': 'zc-fla-select-input-wrap',
    'bottom': 'zc-fla-select-input-wrap-bottom',
    'none': 'zc-input-none'
}
const activeMap = {
    'all': 'zc-fla-select-input-wrap-active',
    'bottom': 'zc-fla-select-input-wrap-bottom-active',
    'none': 'input-bottom-active'
}


interface Props {
    onVal?: any
    placeholder?: string
    width?: number,
    options?: ISelect[],
    display?: 'grid' | 'line',
    label?: string,
    name?: any,
    hidelabel?: boolean,
    hidechart?: boolean,
    size?: IFomItemSize,
    fld?: any,
    value?: any,
    onChange?: any
    style?: any
    setFilterOptions?: any
    onAlt?: any
    filter?: any
    disabled?: boolean
    bordered?: borderedType
}
const FbaWareSelect = (props: Props) => {
    const {placeholder, label, hidelabel, hidechart, fld, filter, disabled = false, bordered = 'all'} = props;
    const [visible, setVisible] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [resultValue, setResultValue] = useState('');
    const [alt, setAlt] = useState<string>('')
    const [isArrowHover, setIsArrowHover] = useState(false);
    const containerRef = useRef<any>()
    const [options, setOptions] = useState<any>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [filterOptions, setFilterOptions] = useState<IDestination[]>([]);
    const [itemData, setItemData] = useState({});

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (!isParentDom(e.target, containerRef.current)) {
                setVisible(false)
            }
        })
    }, [])
    useEffect(() => {
        const val = props.value?.[fld] || props.value
        if (val?.includes('||')) {
            setResultValue(val?.split('||')[1])
        } else {
            setResultValue(val)
        }
        setAlt(val)

    }, [props.value]);

    useEffect(() => {
        if ((inputValue || resultValue) && options.length) {
            const json = options.reduce((result: boolean, item: any) => {
                const s = item.destinations.find((sub: any) => sub.code.toUpperCase() === (resultValue.toUpperCase() || inputValue.toUpperCase()))
                if (s) {
                    result = true
                }
                return result
            }, false)
            props?.setFilterOptions?.(json)
        }
        // console.log(options.find((i: any) =>))
    }, [resultValue, inputValue, options])

    const sizeClass = {
        wrap: `${borderedMap[bordered]} ${visible ? activeMap[bordered] : ''} zc-fla-select-wrap-height-${props.size}`,
        optionWrap: `zc-fla-select-grid-option-wrap ${!visible ? 'zc-fla-select-option-wrap-hide' : ''} zc-fla-select-option-top-${props.size}`,
        inputWrap: `zc-fla-select-input zc-fla-select-wrap-height-${props.size}`,
    }
    const onSelectChange = (item: any) => {
        setResultValue(item.code);
        setAlt(`${item.country}||${item.code}`)
        setVisible(false);
        setInputValue('');
        fld && props?.onChange?.({
            [fld]: item?.code
        })
        fld && props?.onAlt?.({
            [fld]: `${item.country}||${item.code}`
        })
        fld && props?.onVal?.(`${item.country}||${item.code}`)
    }



    useEffect(() => {
        Api.Ware.GetWareAndGroup().then((res: IFbaWareResp) => {
            if (res.state) {
                const json = res.data.reduce((result: any, item: any) => {
                    result[item.code] = item.countryName
                    return result
                }, {})
                sessionStorage.setItem('country', JSON.stringify(json))
                if (filter && res.data?.filter((i: any) => i.code === filter)) {
                    setOptions(res.data?.filter((i: any) => i.code === filter));
                } else {
                    setOptions(res.data);
                }
            }
        })
    }, [filter])

    const onInputChange = (e: any) => {
        if (!visible) {
            setVisible(true)
        }
        if (e.target.value.trim() != resultValue) {
            setResultValue('')
            setAlt('')
        }
        const key = e.target.value;
        setInputValue(key);
        if (e.target.value) {
            const result: IDestination[] = [];
            for (let i = 0; i < options.length; i++) {
                const item = options[i];
                item.destinations.filter((subItem: any) => {
                    if (subItem.code.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
                        result.push({...subItem, countryName: item.countryName, index: i})
                    }
                })
            }
            setFilterOptions(result);
        }
    }

    const onSeachSelect = (item: IDestination) => {
        setCurrentIndex(item.index);
        setResultValue(item.code);
        setAlt(`${item.country}||${item.code}`)
        fld && props?.onAlt?.({
            [fld]: `${item.country}||${item.code}`
        })
        fld && props?.onVal?.(`${item.country}||${item.code}`)

        setInputValue('')
    }

    const getValidKey = () => {
        if (!options) {
            return;
        }
        const letterMap = Array.from({length: 26})
            .reduce((t: any, i: any, index: number) => {
                const name = String.fromCharCode(65 + index);
                t[name] = {key: name};
                return t;
            }, {});
        if (options.length > 0) {
            const dataMap: any = options[currentIndex]?.destinations?.reduce((t: any, i: IDestination) => {
                const key = i.code.slice(0, 1);
                if (key != '/') {
                    const prev =  t[key]&&t[key].values;
                    t[key].values = !prev ? [i] : [...prev, i];
                }
                return t;
            }, letterMap);
            Object.values(dataMap).forEach((item:any)=>{
                if(!item.values){
                    delete dataMap[item.key];
                }
            })
            setItemData(dataMap);
        }
    }

    useEffect(() => {
        getValidKey();
    }, [currentIndex, options])

    // 回车事件
    const onKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            const k_json: any = filterOptions.find((i: IDestination) => inputValue.toUpperCase() === i.code)
            setResultValue(k_json.code);
            setAlt(`${k_json.country}||${k_json.code}`)
            setVisible(false);
            setInputValue('');
            fld && props?.onChange?.({
                [fld]: k_json?.code
            })
            fld && props?.onAlt?.({
                [fld]: `${k_json.country}||${k_json.code}`
            })
            fld && props?.onVal?.(`${k_json.country}||${k_json.code}`)

        }
    }

    const onFocus = () => {
        if (disabled) {
            return
        }
        setVisible(true)
    }

    return (
        <div className="zc-fla-select-container" ref={containerRef}
             style={{width: props.width || 'auto', ...props.style}}>
            <div className={sizeClass?.wrap} onClick={onFocus} onMouseEnter={() => {
                setIsArrowHover(true)
            }} onMouseLeave={() => setIsArrowHover(false)}>
                {!hidelabel && <span>{label}{!hidechart && '：'}</span>}
                <input autoComplete="off" className={sizeClass.inputWrap} placeholder={placeholder}
                       onKeyDown={onKeyDown}
                       alt={alt}
                       value={resultValue || inputValue} onChange={onInputChange} name={props.fld}/>
                <input autoComplete="off" style={{display: 'none'}} placeholder={placeholder}
                       onKeyDown={onKeyDown}
                       value={alt} onChange={onInputChange} name={props.fld}/>
                <div className="zc-fla-select-right" onClick={() => setInputValue('')}>
                    {!(isArrowHover && inputValue) &&
                    <i className={visible ? "iconfont icon-jiantou-shang" : "iconfont icon-jiantou-xia"}/>}
                    {(isArrowHover && inputValue) && <i className="clear-icon iconfont icon-guanbi"/>}
                </div>
            </div>
            {!inputValue && <div className={sizeClass.optionWrap}>
                <div className="zc-fba-select-header">
                    {options?.map?.((item: any, index: number) =>
                        <div key={index} onClick={() => setCurrentIndex(index)}
                             className={index == currentIndex ? "zc-fba-select-header-item-active" : "zc-fba-select-header-item"}>{item.countryName}</div>
                    )
                    }
                </div>
                <div className="flex flex-row">
                    {options.length > 0 && options[currentIndex].destinations.length > 20 &&
                    <div className="flex flex-column zc-fba-select-content">
                        {
                            Object.values(itemData).map((item: any) =>
                                <div className="flex flex-row zc-fba-select-item" key={item.key}>
                                    <span className="zc-fba-letter-key">{item.key}</span>
                                    <div className="zc-fba-select-sub-content">
                                        {
                                            item.values.map((item: IDestination, index: number) =>
                                                <div key={index}
                                                     className={`zc-fla-select-option pointer ${resultValue === item.code && 'zc-fla-select-active' || ''}`}
                                                     onClick={() => onSelectChange(item)}>{item.code}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>}
                    {options.length > 0 && options[currentIndex].destinations.length <= 20 &&
                    <div className="flex flex-column zc-fba-select-content">
                        {
                            options[currentIndex]?.destinations.map((item: IDestination, index: number) =>
                                <div key={index}
                                     className={`zc-fla-select-option pointer ${resultValue === item.code && 'zc-fla-select-active' || ''}`}
                                     onClick={() => onSelectChange(item)}>{item.code}</div>
                            )
                        }
                    </div>}
                </div>
            </div>}
            {inputValue && <div className={sizeClass.optionWrap}>
                <div className="zc-fba-select-content">
                    {
                        filterOptions.map((item: IDestination, index: number) =>
                            <div key={index}
                                 className={`zc-fla-select-option pointer ${inputValue.toUpperCase() === item.code && 'zc-fla-select-active' || ''}`}
                                 onClick={() => onSeachSelect(item)}>
                                <Html2Text keyword={inputValue} data={item.code}/><span
                                style={{fontSize: '12px', color: '#999'}}>({item.countryName})</span>
                            </div>
                        )
                    }
                    {filterOptions.length == 0 && <Empty text="暂时没有您搜索的FBA仓库代码"/>}
                </div>
            </div>}
        </div>
    )
}

export default FbaWareSelect;